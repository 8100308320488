.App {
  text-align: center;
  background-image: url('./frescera-blackbackground.png');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.David-logo {
  height: 40vmin;
  pointer-events: none;
  padding-bottom: 5px;
}

.Frescera-logo {
  height: 30vmin;
  pointer-events: none;
}

.comingsoon-text {
  font-family: "DM Serif Display";
  height: 40vmin;
  color: #000000;
  font-size: calc(5px + 2vmin);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'dmSerifDisplay';
  src: local('DM_Serif_Display'), url(./fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf) format('truetype');
}

.frescera-text {
  height: 40vmin;
  font-size: calc(30px + 2vmin);
  color: #000000;
  font-family: dmSerifDisplay;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.underconstruction-text {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Helvetica Neue";
  margin-top: -300px;
}

.waitlist-button{
  background-color: #000000;
  color: #FFFFFF;
  font-family: dmSerifDisplay;
  /**display:block;**/
  border-style: solid;
  border-color: transparent;
  border-radius: 30px;
  height: 50px;
  width: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  display: flex;
  margin-top: -5px;
}

.waitlist-button:focus{
  background-color: #404040;
}

.content-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
